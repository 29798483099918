const languages: { [key: string]: string }  = {

  cs: 'Česky',
  de: 'Deutsch',
  en: 'English',
  es: 'Español',
  latam: 'Español (LATAM)',
  fr: 'Français',
  // sl: 'Slovenščina',
  it: 'Italiano',
  nl: 'Nederlands',
  fi: 'Suomalainen',
  pl: 'Polski',
  pt: 'Português',
  ro: 'Românesc',
  sv: 'Svenska',
  zh: 'Chinese',

  hu: 'Magyar',
  bg: 'български',
  hr: 'Hrvatski',
  da: 'Dansk',
  et: 'Eesti keel',
  el: 'ελληνικός',
  ga: 'Gaeilge',
  is: 'íslenskur',
  no: 'Norsk'
  // ru: 'русский'
}

interface Language {
  iso    : string
  in_url : boolean
  country : string
}

// check if uri contains a language iso2 code
const uri_lang = window.location.pathname.replace(/^\/([^\/]+).*/i, '$1')

// gets current language data
const init_language = (default_lang: string): Language => {
  // language data
  let language: Language = {
    iso     : default_lang,
    in_url  : false,
    country : default_lang
  }

  // attempt to get locale from url
  if (uri_lang !== undefined && uri_lang !== '/' && languages[uri_lang] !== undefined) {
    language.iso = uri_lang
    language.in_url = true
    language.country = uri_lang
  }

  switch (language.country) {
    case 'en':
      language.country = 'gb'
      break
    case 'cs':
      language.country = 'cz'
      break
    case 'zh':
      language.country = 'cn'
      break
    case 'latam':
      language.country = 'mx'
      break
    case 'da':
      language.country = 'dk'
      break
    case 'et':
      language.country = 'ee'
      break
    case 'el':
      language.country = 'gr'
      break
    case 'ga':
      language.country = 'ie'
      break
  }

  return language
}


export { init_language, Language, languages }
