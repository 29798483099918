
import Hero from '@/components/home/hero.vue'
import Features from '@/components/home/features.vue'
import Showcase from '@/components/home/showcase.vue'
import { XHR } from '@/utils/xhr'
import { router } from '@/router'

export default {
  name: 'Home',
  components: { Showcase, Features, Hero },
  mounted () {
    const xhr = new XHR()
    xhr.get('/login-verify').then(x => {
      if (x.data === '1') {
        router.push('/dash')
      }
    })
  }
}
