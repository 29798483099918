
import { config } from '@/conf'
import { languages } from '@/utils/language'

export default {
  name: 'LangSelectModal',
  setup () {

    const flag = (l: string) => {
      switch (l) {
        case 'en':
          l = 'gb'
          break
        case 'cs':
          l = 'cz'
          break
        case 'zh':
          l = 'cn'
          break
        case 'latam':
          l = 'mx'
          break
        case 'da':
          l = 'dk'
          break
        case 'et':
          l = 'ee'
          break
        case 'el':
          l = 'gr'
          break
        case 'ga':
          l = 'ie'
          break
      }

      return `https://hatscripts.github.io/circle-flags/flags/${l}.svg`
    }

    return {
      config, languages, flag
    }
  }

}
